<template>
  <div>
    <ControlNavbar/>

    <div class="container">
      <br/>
      <div class="row d-flex justify-content-between">
        <div class="left" @click="previousMonth()">
          <a-icon type="left" v-if="$session.get('lang') == 'en'"/>
          <a-icon type="right" v-if="$session.get('lang') == 'ar'"/>
        </div>
        <div class="date">
          <h1>{{ date }}</h1>
        </div>
        <div class="right" @click="nextMonth()">
          <a-icon type="right" v-if="$session.get('lang') == 'en'"/>
          <a-icon type="left" v-if="$session.get('lang') == 'ar'"/>
        </div>
      </div>
      <div class="row d-flex justify-content-center" v-if="targetObject">
        <br/>
        <div
            v-for="(target, index) in targetObject.targets"
            :key="index"
            class="row"
        >
          <div class="col">
            <a-card hoverable style="width: max-content">
              <a-card-meta title="">
                <template slot="description">
                  <div class="row">
                    <div class="col-4">
                      <img
                          slot="cover"
                          alt="example"
                          :src="target.pictureUrl"
                          width="80%"
                      />
                    </div>
                    <div class="col" style="text-align: start">
                      <div v-for="(data, index) in target.data" :key="index">
                        {{ data.label }} {{ data.text }}
                      </div>
                      <hr/>
                      <div
                          v-for="(bracket, index) in target.brackets"
                          :key="index"
                      >
                        {{ bracket.target }} {{ bracket.incentive }}
                      </div>
                    </div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
            <br/>
          </div>
          <div class="col">
            <a-card hoverable style="width: max-content">
              <a-card-meta title="">
                <template slot="description">
                  <div class="row">
                    <div class="col-4">
                      <img
                          slot="cover"
                          alt="example"
                          :src="target.pictureUrl"
                          width="80%"
                      />
                    </div>
                    <div class="col" style="text-align: start">
                      <div v-for="(data, index) in target.data" :key="index">
                        {{ data.label }} {{ data.text }}
                      </div>
                      <hr/>
                      <div
                          v-for="(bracket, index) in target.brackets"
                          :key="index"
                      >
                        {{ bracket.target }} {{ bracket.incentive }}
                      </div>
                    </div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
            <br/>
          </div>
          <div class="col">
            <a-card hoverable style="width: max-content">
              <a-card-meta title="">
                <template slot="description">
                  <div class="row">
                    <div class="col-4">
                      <img
                          slot="cover"
                          alt="example"
                          :src="target.pictureUrl"
                          width="80%"
                      />
                    </div>
                    <div class="col" style="text-align: start">
                      <div v-for="(data, index) in target.data" :key="index">
                        {{ data.label }} {{ data.text }}
                      </div>
                      <hr/>
                      <div
                          v-for="(bracket, index) in target.brackets"
                          :key="index"
                      >
                        {{ bracket.target }} {{ bracket.incentive }}
                      </div>
                    </div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
            <br/>
          </div>
          <div class="col">
            <a-card hoverable style="width: max-content">
              <a-card-meta title="">
                <template slot="description">
                  <div class="row">
                    <div class="col-4">
                      <img
                          slot="cover"
                          alt="example"
                          :src="target.pictureUrl"
                          width="80%"
                      />
                    </div>
                    <div class="col" style="text-align: start">
                      <div v-for="(data, index) in target.data" :key="index">
                        {{ data.label }} {{ data.text }}
                      </div>
                      <hr/>
                      <div
                          v-for="(bracket, index) in target.brackets"
                          :key="index"
                      >
                        {{ bracket.target }} {{ bracket.incentive }}
                      </div>
                    </div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
            <br/>
          </div>

          <br/>
        </div>
      </div>

      <br/><br/><br/>
    </div>
    <div
        class="row d-flex justify-content-center align-items-end"
        v-if="targetObject && targetObject.header"
    >
      <br/>
      <h4>{{ targetObject.header.label }}: {{ targetObject.header.text }}</h4>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";
import firebase from "firebase";
// import Slick from "vue-slick";
import ControlNavbar from "@/components/ControlNavbar";

export default {
  name: "Targets",
  components: {
    ControlNavbar,
  },
  data() {
    return {
      id: null,
      targetObject: null,
      date: `${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
    };
  },
  methods: {
    previousMonth() {
      let dateStr = this.date;
      dateStr = dateStr.split("/");
      let month = Number(dateStr[0]);
      let year = Number(dateStr[1]);
      if (month - 1 <= 0) {
        month = 12;
        year--;
      } else {
        month--;
      }
      this.date = `${month}/${year}`;
      this.getTargets(month, year);
    },
    nextMonth() {
      let dateStr = this.date;
      dateStr = dateStr.split("/");
      let month = Number(dateStr[0]);
      let year = Number(dateStr[1]);
      if (month + 1 > 12) {
        month = 1;
        year++;
      } else {
        month++;
      }
      this.date = `${month}/${year}`;
      this.getTargets(month, year);
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    checkApiResponse(result) {
      if (result.headers.sessioncounter) {
        this.$session.set("sessionCounter", result.headers.sessioncounter);
      }
      if (!result.data.status) {
        // error
        this.$message.error(result.data.dialog.message);
        console.error("server switch error : ", result);
        if (result.data.code === "auth.session_expired") {
          // log user out
          this.logout();
        }
      } else {
        return true;
      }
      return false;
    },
    logout() {
      this.getAxios()
          .post(`${this.$session.get("baseURI")}account/session/deactivate`, {
            sessionId: this.$session.get("sessionId"),
          })
          .then((result) => {
            if (this.checkApiRes(result, {
              sessionId: this.$session.get("sessionId"),
            }, "account/session/deactivate", "post")) {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            } else {
              this.$session.clear();
              firebase
                  .auth()
                  .signOut()
                  .then(() => {
                    this.$router.replace("/login").catch(() => {
                    });
                    console.log("Sign-out successful");

                    // Sign-out successful.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error happened.
                  });
            }
          });
    },
    getTargets(month, year) {
      console.log("initiating getTargets...");
      let values = {
        year: year,
        month: month,
      };
      this.getAxios()
          .post(
              `${this.$session.get("baseURI")}transaction/targets/${this.id}`,
              values
          )
          .then((result) => {
            if (this.checkApiRes(result, values, `transaction/targets/${this.id}`, "post")) {
              // ok
              console.log("get Targets", result);
              this.targetObject = result.data.data;
            }
          });
    },
  },
  created: function () {
    var today = new Date();

    console.log("data: ", this.$route.params.id || this.$session.get("backup"));
    if (!this.$route.params.id) {
      // undefined
      this.id = this.$session.get("backup");
      this.getTargets(today.getMonth() + 1, today.getFullYear());
    } else {
      this.id = this.$route.params.id;
      this.$session.set("backup", this.$route.params.id);
      this.getTargets(today.getMonth() + 1, today.getFullYear());
    }
  },
};
</script>

<style lang="scss">
.slick-track {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>

<style lang="scss" scoped>
.left,
.right {
  color: #e62946;
  font-size: xx-large;
}
</style>
